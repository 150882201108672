@import "./../../../globalstyles/Variables.scss";

.main .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)>.container>.item:not(.right):not(.borderless):first-child {
  border: none;
}

.main .ui.menu {
  height: 70px;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  background-color: $primary-color;
  
  .header.item {
    font-size: 1.6rem;
  }

  .item {
    color: #fff;  
    padding: 0;
    font-size: 1.3rem;
  
    &:last-child {
      margin-left: 10px;
    }

    &:first-child {
      margin-right: 10px;
    }

    &:not(:last-child) &:not(:first-child) {
      margin: 5px;
    }

    &:before {
      width: 0px;
    }
    
    &.active {
      color: #fff;
      background: none;

      &:hover {
        color: #fff;
        background: none;
        text-decoration: underline;
      }
    }

    &:hover {
      color: #fff;
      background: none;
      text-decoration: underline;
    }
  }

  .ui.item.dropdown {
    &:hover {
      background: none;
      color: #fff;
    }
  }
}
