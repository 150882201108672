@import "../../globalstyles/Variables";

.login {
  #login-illustration {	
    background-color: $primary-color;	
    height: 100vh;
  }

  #login-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #login-form {
      width: 400px;
    }
  }
  }


